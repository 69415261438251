// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-os-licenses-jsx": () => import("./../../../src/pages/os-licenses.jsx" /* webpackChunkName: "component---src-pages-os-licenses-jsx" */),
  "component---src-pages-releases-jsx": () => import("./../../../src/pages/releases.jsx" /* webpackChunkName: "component---src-pages-releases-jsx" */),
  "component---src-pages-upcoming-releases-jsx": () => import("./../../../src/pages/upcoming-releases.jsx" /* webpackChunkName: "component---src-pages-upcoming-releases-jsx" */),
  "component---src-templates-artist-template-jsx": () => import("./../../../src/templates/artistTemplate.jsx" /* webpackChunkName: "component---src-templates-artist-template-jsx" */),
  "component---src-templates-release-template-jsx": () => import("./../../../src/templates/releaseTemplate.jsx" /* webpackChunkName: "component---src-templates-release-template-jsx" */)
}

